<template>
  <div>
    <div class="breadcrumb bg_img_order justify-content-center align-items-center p-3">
      <h1 class="breadcrumb-item text-white">{{$t("message.Shopping_Bag")}}</h1>
      <h1 class="breadcrumb-item text-white">{{$t("message.Checkout_Details")}}</h1>
      <h1 class="breadcrumb-item active">{{$t("message.Complete_Order")}}</h1>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{$t("message.Tran_Addr")}}</th>
            <th scope="col">{{$t("message.Billing_Address")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTONAME}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTONAME}}</td>
          </tr>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOSTREET}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOSTREET}}</td>
          </tr>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOCITY}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOCITY}}</td>
          </tr>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOSTATE}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOSTATE}}</td>
          </tr>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE}}</td>
          </tr>
          <tr>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOZIP}}</td>
            <td>{{result.PAYMENTREQUEST_0_SHIPTOZIP}}</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td>{{$t("message.Total_Amount")}}:</td>
            <td>
              <strong>{{result.PAYMENTREQUEST_0_AMT}}</strong>
            </td>
          </tr>
          <tr>
            <td>{{$t("message.Currency_Code")}}:</td>
            <td>
              <strong>{{result.CURRENCYCODE}}</strong>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-card
      border-variant="info"
      :header="$t('message.Your_Contact_Information')"
      header-bg-variant="secondary"
      text-variant="white"
      align="center"
      m-1
    >
      <b-card-text>
        <div role="group">
          <label for="input-live">{{$t("message.Telephone_Format")}}:(+1234567)</label>
          <b-form-input :type="tel" v-model="phones"></b-form-input>
        </div>
      </b-card-text>
      <b-button href="#" variant="warning" v-if="lan=='en'" @click="toPayComple()">Pay With Paypal</b-button>
      <b-button href="#" variant="warning" v-if="lan=='cn'">支付宝支付</b-button>
      <p v-if="phones==''" class="text-danger">{{$t("message.Please_Enter_Phone")}}</p>
    </b-card>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapGetters } from "vuex";
export default {
  name: "PayReviewSection",
  props: {
    result: {}
  },
  data() {
    return {
      phones: ""
    };
  },
  computed: {
    ...mapGetters(["lan"])
  },
  methods: {
    toPayComple() {
      if (this.phones == "") {
        return;
      }
      // axios({
      //         url: "http://192.168.1.141:9000/paypal/checkout/finish",
      //         method: 'get',
      //         params: {
      //             page: 'return',
      //             phone: this.phones,
      //             orderno: this.result.L_PAYMENTREQUEST_0_NUMBER0,
      //             shipping_method: this.result.PAYMENTREQUEST_0_SHIPTONAME,
      //             PAYMENTREQUEST_0_AMT: this.result.PAYMENTREQUEST_0_AMT,
      //             __PAYMENTREQUEST_0_SHIPTONAME: this.result.PAYMENTREQUEST_0_SHIPTONAME,
      //             __PAYMENTREQUEST_0_SHIPTOSTREET: this.result.PAYMENTREQUEST_0_SHIPTOSTREET,
      //             __PAYMENTREQUEST_0_SHIPTOCITY: this.result.PAYMENTREQUEST_0_SHIPTOCITY,
      //             __PAYMENTREQUEST_0_SHIPTOSTATE: this.result.PAYMENTREQUEST_0_SHIPTOSTATE,
      //             __PAYMENTREQUEST_0_SHIPTOCOUNTRYNAME: this.result.PAYMENTREQUEST_0_SHIPTOCOUNTRYNAME,
      //             __PAYMENTREQUEST_0_SHIPTOZIP: this.result.PAYMENTREQUEST_0_SHIPTOZIP,
      //         }
      //     })
      //     .then((result) => {

      //     })

      window.location =
        "/paypal/checkout/finish/vue?page=return" +
        "&phone=" +
        this.phones +
        "&orderno=" +
        this.result.L_PAYMENTREQUEST_0_NUMBER0 +
        "&shipping_method=" +
        this.result.PAYMENTREQUEST_0_SHIPTONAME +
        "&PAYMENTREQUEST_0_AMT=" +
        this.result.PAYMENTREQUEST_0_AMT +
        "&__PAYMENTREQUEST_0_SHIPTONAME=" +
        this.result.PAYMENTREQUEST_0_SHIPTONAME +
        "&__PAYMENTREQUEST_0_SHIPTOSTREET=" +
        this.result.PAYMENTREQUEST_0_SHIPTOSTREET +
        "&__PAYMENTREQUEST_0_SHIPTOCITY=" +
        this.result.PAYMENTREQUEST_0_SHIPTOCITY +
        "&__PAYMENTREQUEST_0_SHIPTOSTATE=" +
        this.result.PAYMENTREQUEST_0_SHIPTOSTATE +
        "&__PAYMENTREQUEST_0_SHIPTOCOUNTRYNAME=" +
        this.result.PAYMENTREQUEST_0_SHIPTOCOUNTRYNAME +
        "&__PAYMENTREQUEST_0_SHIPTOZIP=" +
        this.result.PAYMENTREQUEST_0_SHIPTOZIP;
    }
  }
};
</script>
