<template>
    <div >
        <div class="breadcrumb bg_img_order justify-content-center align-items-center p-3">
            <h1 class="breadcrumb-item text-white">{{$t("message.Shopping_Bag")}}</h1>
            <h1 class="breadcrumb-item text-white">{{$t("message.Checkout_Details")}}</h1>
            <h1 class="breadcrumb-item text-white">{{$t("message.Complete_Order")}}</h1>
        </div>
        <img :src="'https://www.shareasale.com/sale.cfm?tracking='+result.orderno+'&amount='+result.PAYMENTINFO_0_AMT+'&merchantID=104195&transtype=sale'" width="1" height="1">
        <h1 class="text-dark ml-3">{{result.PAYMENTREQUEST_0_SHIPTONAME}},Thank you for your Order.</h1>
    
        <p class="ml-3"> phone:{{result.phone}}</p>
        <h1 class="ml-3"> Shipping Details:</h1>
    
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <tbody>
                    <tr>
                        <th scope="row">Order number:</th>
                        <td>{{result.orderno}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Transaction ID:</th>
                        <td>{{result.PAYMENTINFO_0_TRANSACTIONID}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Transaction Type:</th>
                        <td>{{result.PAYMENTINFO_0_TRANSACTIONTYPE}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Payment Total Amount:</th>
                        <td>{{result.PAYMENTINFO_0_AMT}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Currency Code:</th>
                        <td>{{result.PAYMENTINFO_0_CURRENCYCODE}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Payment Status:</th>
                        <td>{{result.PAYMENTINFO_0_PAYMENTSTATUS}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Payment Type:</th>
                        <td>{{result.PAYMENTINFO_0_PAYMENTTYPE}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
  
            
   
        <!-- <p class="text-center border border-success font-weight-bolder">
            Back
        </p> -->
        <b-button block variant="success ml-3" @click="toHome()">{{$t("message.Back")}}</b-button>
    
    
    
    </div>
</template>

<script>
export default {
    name: 'PayReturnSection',
    props: {
        result: {}
    },
    // mounted() {
    //     const s = document.createElement('script');
    //     s.type = 'text/javascript';
    //     s.src = 'https://www.dwin1.com/19038.js';
    //     s.defer = 'defer';
    //     document.body.appendChild(s);
    // },
    methods: {
        toHome() {
            this.$router.push({
                name: "enhome",
            });
        },
    }
}
</script>

