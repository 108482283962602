<template>
  <div class="container">
    <Head-section></Head-section>
    <PayReview-Section :result="result" v-if="page=='review'"></PayReview-Section>
    <PayReturn-Section :result="result" v-if="page=='return'"></PayReturn-Section>
  </div>
</template>

<script>
import PayReviewSection from "./PayReviewSection.vue";
import PayReturnSection from "./PayReturnSection.vue";
import HeadSection from "./StoreHeadSection.vue";
export default {
  name: "Paypal",
  components: { HeadSection, PayReviewSection, PayReturnSection },
  data() {
    return {
      result: this.$route.query.result,
      page: ""
    };
  },
  created() {
    this.page = this.$route.query.page;
    this.result = JSON.parse(this.$route.query.result);
    // this.page='review'
    // this.result='{"BUILD":"52821064","L_PAYMENTREQUEST_0_NAME0":"(black)V (barrel)","FIRSTNAME":"Test","EMAIL":"cyk-buyer@gdliwei.com","L_NAME0":"(black)V (barrel)","L_AMT0":"249.99","LASTNAME":"Buyer","TIMESTAMP":"2019-05-25T00:49:38Z","SHIPPINGAMT":"0.00","L_PAYMENTREQUEST_0_TAXAMT0":"0.00","TOKEN":"EC-5L240895VF904223T","SHIPTOZIP":"200000","PAYMENTREQUEST_0_SHIPTONAME":"Buyer Test","L_PAYMENTREQUEST_0_AMT0":"249.99","INSURANCEOPTIONOFFERED":"false","PAYMENTREQUEST_0_SHIPTOZIP":"200000","SHIPTOCITY":"Shanghai","PAYMENTREQUEST_0_SHIPTOCOUNTRYNAME":"China","L_PAYMENTREQUEST_0_QTY0":"1","AMT":"249.99","TAXAMT":"0.00","PAYMENTREQUEST_0_HANDLINGAMT":"0.00","SHIPDISCAMT":"0.00","PAYMENTREQUEST_0_INSURANCEOPTIONOFFERED":"false","BILLINGAGREEMENTACCEPTEDSTATUS":"0","PAYMENTREQUEST_0_SHIPTOCITY":"Shanghai","COUNTRYCODE":"C2","PAYMENTREQUEST_0_AMT":"249.99","PAYMENTREQUEST_0_CURRENCYCODE":"USD","PAYMENTREQUESTINFO_0_ERRORCODE":"0","SHIPTONAME":"Buyer Test","L_PAYMENTREQUEST_0_NUMBER0":"190525084923662908","CHECKOUTSTATUS":"PaymentActionNotInitiated","SHIPTOCOUNTRYNAME":"China","CORRELATIONID":"667908eabe15d","SHIPTOSTATE":"Shanghai","L_DESC0":"(black)V (barrel)","HANDLINGAMT":"0.00","PAYMENTREQUEST_0_TAXAMT":"0.00","ITEMAMT":"249.99","L_PAYMENTREQUEST_0_DESC0":"(black)V (barrel)","PAYERSTATUS":"verified","PAYMENTREQUEST_0_INSURANCEAMT":"0.00","PAYMENTREQUEST_0_SHIPDISCAMT":"0.00","PAYMENTREQUEST_0_ADDRESSSTATUS":"Confirmed","PAYMENTREQUEST_0_SHIPTOSTATE":"Shanghai","ACK":"Success","SHIPTOSTREET":"NO 1 Nan Jin Road","SHIPTOCOUNTRYCODE":"C2","L_TAXAMT0":"0.00","CURRENCYCODE":"USD","INSURANCEAMT":"0.00","PAYMENTREQUEST_0_SHIPTOSTREET":"NO 1 Nan Jin Road","PAYERID":"6UPQCWNZUWJVL","ADDRESSSTATUS":"Confirmed","VERSION":"109.0","PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE":"C2","L_NUMBER0":"190525084923662908","L_QTY0":"1","PAYMENTREQUEST_0_ITEMAMT":"249.99","PAYMENTREQUEST_0_SELLERPAYPALACCOUNTID":"cyk-facilitator@gdliwei.com"}'

  }
};
</script>

